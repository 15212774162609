import React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import { PageHeader, Services } from "../components/sections"
import {
  Content,
  Description,
  Firewall,
  LAN,
  Routers,
  SdWan,
  Vpn,
} from "../images/managednetwork"
import {
  BlackSection,
  DetailSection,
  IntroSection,
  OfferSection,
  Points,
} from "../components/structure"
import { AboutCard } from "../components/elements"

const weOffer = [
  {
    id: 1,
    src: SdWan,
    title: "Managed SD-WAN, Hybrid Network & MPLS",
    content:
      "Improve your network performance and user experience with our managed SD-WAN, hybrid network, and MPLS. The hybrid approach utilises both SD-WAN and MPLS and integrates cost-effective broadband to reap the benefits of both networking solutions, including reliability and reduced operational costs.",
    order: 0,
  },
  {
    id: 2,
    src: Vpn,
    title: "VPN Setup",
    content:
      "Being able to work remotely has never been so important. Our team of experts will set up and manage your VPN to enable your business to run smoothly regardless of where you’re located. Grow your business by connecting your remote workers with an effective VPN.",
    order: 1,
  },
  {
    id: 3,
    src: Routers,
    title: "Managed Router",
    content:
      "Our managed router service takes care of installation, configuration, maintenance, and ongoing network monitoring, as well as device procurement and rental. You can focus on your business while we cover network management and troubleshooting on a daily basis.",
    order: 0,
  },
  {
    id: 4,
    src: LAN,
    title: "Managed Local Network Switches",
    content:
      "Our managed local network switches service gives you the ultimate control over your network. Enjoy benefits such as unified communications and virtual LANs, lifecycle management, and restoration of configurations, all of which increase business efficiency.",
    order: 1,
  },
  {
    id: 5,
    src: Firewall,
    title: "Managed Internet Firewall",
    content:
      "Internet firewall management will help to protect your business network against cyberattacks. Configuration, setup, and management of your firewall is all included to ensure that you are well-defended against any internet activity that poses a risk to your business.",
    order: 0,
  },
]

const points = [
  {
    id: 1,
    point: "Cost-effective and reliable network configuration and setup",
  },
  {
    id: 2,
    point: "Ongoing management and monitoring",
  },
  {
    id: 3,
    point: "User ease and improved business productivity",
  },
  {
    id: 4,
    point: "Troubleshooting and technical support",
  },
  {
    id: 5,
    point: "Increased cyber security",
  },
]

export default function ManagedNetwork() {
  const data = useStaticQuery(graphql`
    query {
      index: file(relativePath: { eq: "headers/networkProduct.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const index = [
    data.index.childImageSharp.fluid,
    `linear-gradient(0deg,
      rgba(0, 0, 0, 0.30),
      rgba(0, 0, 0, 0.30))`,
  ].reverse()
  return (
    <Layout>
      <SEO title="Managed Network" />
      <PageHeader
        fluid={index}
        catchy="Managed Network"
        subtitle="Get ahead with effective network management"
        title="Leave your network to us. Your business deserves it."
      />
      <IntroSection
        title="Managed Network"
        content="A managed network gives your business a secure way to connect between different sites and enables the seamless transfer of data, increasing the productivity of your business. Our managed network services offer provisioning, configuration, and monitoring of your business network. Additionally, our qualified team will be on hand to deal with any network issues that arise, saving you from the stress and distraction of IT problems."
        src={Description}
      />
      <BlackSection title="Leave your network to us" />
      <IntroSection
        title="How we will help you?"
        content="Managed network services are immensely beneficial to a business, especially now that so many organisations rely so heavily on technology. A managed network will ensure a secure and reliable Wi-Fi connection and defence against cyberattacks as the service includes ongoing monitoring and troubleshooting, as well as continuously updated technology. A properly managed network will result in your company running more efficiently and will reduce technical costs in the long term. It will also eliminate technical distractions while you and your personnel focus on the core of your business."
        src={Content}
      />
      <OfferSection title="What we offer:">
        {weOffer.map((item, id) => (
          <AboutCard
            key={item.id}
            order={item.order}
            src={item.src}
            to={item.to}
            title={item.title}
            content={item.content}
          />
        ))}
      </OfferSection>
      <DetailSection paragraph1="In a post-Covid world, where technology carries so much of a company’s weight, you have to be able to rely on an effective network. Network problems that hinder your business growth, like slow, unreliable Wi-Fi, unsecure data transfers, and outdated technology will become a thing of the past when our skilled team monitors and manages your business network. The advantages of our managed network service are too valuable to ignore, including:">
        {points.map((item, id) => (
          <Points key={item.id}>{item.point}</Points>
        ))}
      </DetailSection>
      <Services />
      <BlackSection title="Call us today for a free quote on our managed network services. Your business deserves it!" />
    </Layout>
  )
}
